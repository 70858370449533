
import { mapGetters } from "vuex"

export default {
  props: {
    noChatBtn: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters("user", ["user"]),
  },
}
