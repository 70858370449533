// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".umKPdjeb{align-items:center;display:flex;flex-basis:50%;justify-content:space-between;margin-left:auto}@media(min-width:1229.98px){.umKPdjeb .S4zjmJA-{display:none}}.L91k3RHT{align-items:center;background-color:var(--color-body);bottom:0;box-shadow:inset 0 24px 20px -20px rgba(0,0,0,.25);display:flex;flex-direction:column;flex-grow:1;justify-content:space-between;left:0;opacity:0;position:fixed;right:0;top:83px;visibility:hidden;z-index:100}.pPjQ3jED{opacity:1;transition:opacity .15s;visibility:visible}@media(min-width:1229.98px){.L91k3RHT{box-shadow:none;flex-direction:row;opacity:1;position:static;transition:none;visibility:visible}}.JyHgPlvp{--size:1.5rem;cursor:pointer;margin-left:auto;-webkit-user-select:none;-moz-user-select:none;user-select:none}.JyHgPlvp:hover{color:var(--color-primary)}@media(min-width:1229.98px){.JyHgPlvp{display:none!important}}.kqLl68-X a,.I3FnX2hO a div:last-child{color:#fff!important}.Z9Gke3TY{align-items:center;display:flex;gap:1rem;justify-content:flex-end;margin-left:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"menu": "umKPdjeb",
	"localeBtnMobile": "S4zjmJA-",
	"dropdown": "L91k3RHT",
	"dropdownExpanded": "pPjQ3jED",
	"icon": "JyHgPlvp",
	"auth": "kqLl68-X",
	"user": "I3FnX2hO",
	"menuWrapper": "Z9Gke3TY"
};
module.exports = ___CSS_LOADER_EXPORT___;
