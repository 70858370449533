// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TWxPSWXm{display:flex;flex-wrap:nowrap;height:100%;overflow:hidden}@media(max-width:1023.98px){.TWxPSWXm{align-items:center;background:var(--color-gray-1);flex-direction:row-reverse;flex-shrink:0;height:6.5rem;justify-content:space-between;padding:0 var(--spacing-sm)}}.zq6AxDdR{align-items:center;display:flex;flex-wrap:nowrap;overflow:hidden}@media(hover:hover){.zq6AxDdR:hover ._8Gc1wEGo{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.zq6AxDdR:active ._8Gc1wEGo{-webkit-text-decoration:underline;text-decoration:underline}}@media(max-width:1023.98px){.zq6AxDdR{padding-right:var(--spacing-sm)}}._36Go9zDp{border-radius:9999px;flex-shrink:0;font-size:.6rem;height:1.5rem;margin-right:var(--spacing-sm);overflow:hidden;width:1.5rem}@media(max-width:1023.98px){._36Go9zDp{height:2.5rem;width:2.5rem}}._8Gc1wEGo{color:var(--color-primary-on-bg);font-size:.81rem;font-weight:500;line-height:1.39;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}@media(max-width:1023.98px){._8Gc1wEGo{font-size:1rem;font-weight:700;line-height:1.6}}.E7fnUatf{display:flex;flex-wrap:nowrap}@media(max-width:1023.98px){.E7fnUatf{align-items:center}}.YilXUFBP{--icon-size:1.25rem;color:var(--color-bg-invert)!important;padding:0 1.63rem}@media(min-width:1023.98px){.YilXUFBP{background:none!important}}@media(min-width:1023.98px)and (hover:hover){.YilXUFBP:hover{opacity:.6}}@media(min-width:1023.98px)and (hover:none){.YilXUFBP:active{opacity:.6}}@media(max-width:1023.98px){.YilXUFBP{padding:var(--spacing-xs)}.YilXUFBP:not(:last-child){margin-right:var(--spacing-sm)}}.But-2ETq{padding-right:0}@media(max-width:1023.98px){.But-2ETq{display:none}}@media(min-width:1023.98px){.M-RYyLPY{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "TWxPSWXm",
	"link": "zq6AxDdR",
	"name": "_8Gc1wEGo",
	"avatar": "_36Go9zDp",
	"btns": "E7fnUatf",
	"btn": "YilXUFBP",
	"btnLogoutDesktop": "But-2ETq",
	"btnLogoutMobile": "M-RYyLPY"
};
module.exports = ___CSS_LOADER_EXPORT___;
