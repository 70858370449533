// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HN-nxWJN .el-dialog__body{padding-bottom:var(--spacing-lg)}.BaIxh2Gd{align-items:flex-start;display:flex}.vvujDh41{--icon-size:1rem;margin-right:var(--spacing-s);margin-top:var(--spacing-xs)}.AnOxoAR1{font-size:1.44rem;font-weight:700;line-height:1.4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"dialog": "HN-nxWJN",
	"title": "BaIxh2Gd",
	"titleBtn": "vvujDh41",
	"titleText": "AnOxoAR1"
};
module.exports = ___CSS_LOADER_EXPORT___;
