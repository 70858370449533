// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6nxQ9s2d{display:flex;margin-left:1rem}@media(max-width:1229.98px){._6nxQ9s2d{margin-left:0;padding:var(--spacing-sm)}}.Uk5FbwcK{align-items:center;display:flex;flex-wrap:nowrap;width:100%}.Uk5FbwcK>:not(:last-child){margin-right:var(--spacing-xs)}@media(max-width:1229.98px){.Uk5FbwcK>:not(:last-child){margin-right:var(--spacing-sm)}.Uk5FbwcK>*{flex-basis:100%;flex-shrink:1}}._38Spwp0n{color:var(--color-bg-invert)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "_6nxQ9s2d",
	"btns": "Uk5FbwcK",
	"btnLogin": "_38Spwp0n"
};
module.exports = ___CSS_LOADER_EXPORT___;
