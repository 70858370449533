// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YRVpvzOW{align-items:center;display:flex;flex-direction:column;flex-grow:1;min-height:100%;padding:26px;position:relative;width:100%}@media(min-width:947.98px){.YRVpvzOW{flex-direction:row;padding:0;width:auto}}@media(min-width:1023.98px){.YRVpvzOW{margin-right:1rem}}.w8z2j\\+pw{align-items:center;color:var(--color-text);display:flex;font-size:18px;font-weight:500;justify-content:center;letter-spacing:.75px;line-height:16px;padding:16px 11px;text-align:center;white-space:nowrap}.w8z2j\\+pw:hover{color:var(--color-primary);-webkit-text-decoration:underline;text-decoration:underline}@media(min-width:1023.98px){.w8z2j\\+pw{font-size:16px;height:64px;padding:0 6px;text-align:left;width:auto}}@media(min-width:1229.98px){.w8z2j\\+pw{padding:0 14px}}.w8z2j\\+pw.x23msnhJ{color:var(--color-primary);cursor:default}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"nav": "YRVpvzOW",
	"link": "w8z2j+pw",
	"nuxtLinkActive": "x23msnhJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
