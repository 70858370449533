
export default {
  data () {
    const DEFAULT_MODE = "login"
    return {
      isModalOpen: false,
      DEFAULT_MODE,
      mode: DEFAULT_MODE,
    }
  },

  computed: {
    title () {
      return this.$t(`pages.auth.${this.mode}.title`)
    },
  },

  mounted () { this.$nuxt.$on("open-login", this.openModal) },
  beforeDestroy () { this.$nuxt.$off("open-login", this.openModal) },

  methods: {
    resetMode () { this.mode = this.DEFAULT_MODE },

    openModal () { this.isModalOpen = true },

    closeModal () {
      this.isModalOpen = false
      this.resetMode()
    },

    beforeClose (done) {
      this.resetMode()
      done()
    },
  },
}
