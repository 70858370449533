
export default {
  data: () => ({
    expanded: false,
  }),

  watch: {
    expanded (expanded) {
      this.$emit("toggle", expanded)
    },
  },

  created () {
    this.$nuxt.$on("close-menu", this.onClose)
  },

  async mounted () {
    await this.$nextTick()
    window.addEventListener("resize", this.onResize)
  },

  beforeDestroy () {
    this.$nuxt.$off("close-menu", this.onClose)
    window.removeEventListener("resize", this.onResize)
  },

  methods: {
    onClose (bool = false) { this.expanded = bool },

    onResize () { this.onClose(false) },

    onToggle () { this.expanded = !this.expanded },
  },
}
