import { render, staticRenderFns } from "./LandingDemoMenu.vue?vue&type=template&id=0f14fdb2"
import script from "./LandingDemoMenu.vue?vue&type=script&lang=js"
export * from "./LandingDemoMenu.vue?vue&type=script&lang=js"
import style0 from "./LandingDemoMenu.vue?vue&type=style&index=0&id=0f14fdb2&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingDemoNav: require('/app/components/landing/demo/header/LandingDemoNav.vue').default,AppLocaleSwitch: require('/app/components/app/AppLocaleSwitch.vue').default,AppHeaderUser: require('/app/components/app/header/AppHeaderUser.vue').default,AppHeaderAuth: require('/app/components/app/header/AppHeaderAuth.vue').default,AppIcon: require('/app/components/app/AppIcon.vue').default})
