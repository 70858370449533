
export default {
  props: {
    noRegistration: { type: Boolean, default: false },
  },

  data: () => ({
    loginPath: "/auth/login",
    registrationPath: "/auth/registration",
  }),

  methods: {
    openLogin () {
      this.$nuxt.$emit("close-menu")
      this.$nuxt.$emit("open-login")
    },
  },
}
